/*
 * Return results like "3l / 100 km"
 */
export function useFormatLiterPer100Km() {
  const { $i18n: { n } } = useNuxtApp()

  return ({ count }: { count: number }): string => {
    return `${n(count, { style: 'unit', unit: 'liter' })}/${n(100, { style: 'unit', unit: 'kilometer' })}`
  }
}
