/*
 * Inputs: "<6", "6-8", ">18"
 * Returns results like "plus de 18l / 100 km"
 */
export function useFormatConsumptionEstimate() {
  const { $i18n: { t } } = useNuxtApp()
  const formatLiterPer100Km = useFormatLiterPer100Km()

  return ({ referentialValue }: { referentialValue: string }) => {
    const [x_string, y_string = null] = referentialValue.split('-')
    if (y_string !== null) {
      return t('commons.estimate.from_to_dynamic', { x_string, y_string: formatLiterPer100Km({ count: +y_string }) })
    }

    const params = { x_string: formatLiterPer100Km({ count: +x_string.slice(1) }) }
    if (x_string.charAt(0) === '<') {
      return t('commons.estimate.less_than_dynamic', params)
    }

    return t('commons.estimate.more_than_dynamic', params)
  }
}
