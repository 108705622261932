const typeMap: Record<number, string> = {
  1: 'data.vehicle.fuel.options.petrol',
  2: 'data.vehicle.fuel.options.diesel',
  3: 'data.vehicle.fuel.options.lpg',
  4: 'data.vehicle.fuel.options.electric',
  5: 'data.vehicle.fuel.options.other',
}

export const useGetTranslatedVehicleFuel = () => {
  const { $i18n: { t } } = useNuxtApp()

  return {
    getTranslatedVehicleFuel: (fuelType: number | null | undefined) => {
      if (!fuelType) {
        return null
      }

      const key = typeMap[fuelType]
      return key ? t(key) : fuelType
    },
  }
}
