const typeMap: Record<number, string> = {
  1: 'data.vehicle.gear.options.manual',
  2: 'data.vehicle.gear.options.automatic',
}

export const useGetTranslatedVehicleGear = () => {
  const { $i18n: { t } } = useNuxtApp()

  return {
    getTranslatedVehicleGear: (type: number | null | undefined) => {
      if (!type) {
        return null
      }

      const key = typeMap[type]
      return key ? t(key) : type
    },
  }
}
